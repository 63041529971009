<template>
    <div class="h-full w-full relative flex items-center justify-center overlay-services service">
      <p class="font-blackMango text-white uppercase text-md font-bold tracking-wider m-3 md:m-10 lg:m-12 relative draw">{{text}}</p>
    </div>
</template>

<script>
export default {
    name: "ServicesBox",
    props: {
      text: String,
    },
}
</script>