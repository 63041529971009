<template>
        <div class="w-screen flex flex-col relative items-center justify-center my-8 md:my-16 lg:my-24">
                  <!-- begin::title section -->
        <div class="flex items-center flex-col px-5 md:px-0">
            <p class="md:text-sectionTitle text-xl w-full text-center font-understock">{{ $t('homepage.services.title') }}</p>
            <p class="text-baselg w-full text-center font-blackMango tracking-wider">
                {{ $t('homepage.services.subtitle') }}</p>
        </div>
        <!-- end::title section -->
            <div class="grid grid-cols-2 lg:grid-cols-3 gap-2 text-center md:h-screen-50 md:gap-5 lg:gap-10 mx-5 md:mx-10 lg:mx-40 auto-rows-fr md:my-7 my-5 lg:my-10 services relative">
                <ServicesBox id="one" :text="$t('ServicesButtons.COMMERCIAL_ADVISORY')" @click.native="navigate(1)"/>
                <ServicesBox id="two" :text="$t('ServicesButtons.CREATIVE_AGENCY')" @click.native="navigate(2)"/>
                <ServicesBox id="three" :text="$t('ServicesButtons.INVESTMENTS')" @click.native="navigate(3)"/>
                <ServicesBox id="four" :text="$t('ServicesButtons.GOLDEN_VISA')" @click.native="navigate(4)"/>
                <ServicesBox id="five" :text="$t('ServicesButtons.STRATEGIC_CONSULTANCY')" @click.native="navigate(5)"/>
                <ServicesBox id="six" :text="$t('ServicesButtons.BRANDING')" @click.native="navigate(6)"/>
            </div>
            <!-- button -->
            <ButtonArrow bgColor="black" class="md:flex hidden mt-6" :text="$t('buttons.discover')" @click.native="navigate()"/>
        </div>
</template>

<script>
import ServicesBox from "@/components/services/ServicesBox.vue"
import ButtonArrow from "@/components/buttons/ButtonArrow.vue";

export default {
    name: "Services",
    components: {
        ServicesBox,
        ButtonArrow
    },
    methods:{
      navigate(link){
        this.$router.push({ name: 'ThisIsLA', query: link && {services: link}  })
      }
    }
}
</script>

