<template>
  <button :class="{ ['hover:bg-darkBronze'+' '+'bg-bronze']: bgColor==='bronze', ['hover:bg-bronze'+' '+'bg-black']: bgColor==='black' }" class="font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-2/3 md:w-64 py-2 md:py-3">
    <span>{{ text }}</span>
    <div class="absolute right-3 flex justify-center">
      <inline-svg fill="white" height="15" src="/svg/arrow/arrowGradient.svg" />
    </div>
  </button>
</template>

<script>
export default {
  name: "ButtonHomepage",
  props: {
    text: String,
    bgColor: String
  },
}
</script>